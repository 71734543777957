import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Accordion } from 'hds-react';
import Playground from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/services/erpnext/customisation",
  "title": "Accordion - Customisation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "customisation",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation`}<a parentName="h2" {...{
        "href": "#customisation",
        "aria-label": "customisation permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "customisation-properties",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation properties`}<a parentName="h3" {...{
        "href": "#customisation-properties",
        "aria-label": "customisation properties permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`You can use the `}<inlineCode parentName="p">{`theme`}</inlineCode>{` property to customise the component. See all available theme variables in the table below.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Theme variable`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--background-color`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Background color of the accordion.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--header-focus-outline-color`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Accordion header focus outline color.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--button-size`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Size of the angle icon.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--content-font-color`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Font colour of the accordion content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--content-font-size`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Font size of the accordion content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--content-line-height`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line height of the accordion content.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--header-font-color`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Font colour of the accordion header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--header-font-size`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Font size of the accodion header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--header-line-height`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Line height of the accordion header.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--padding-horizontal`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Left and right padding of the accordion.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><code>{`--padding-vertical`}</code></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Top and bottom padding of the accordion.`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "customisation-example",
      "style": {
        "position": "relative"
      }
    }}>{`Customisation example`}<a parentName="h3" {...{
        "href": "#customisation-example",
        "aria-label": "customisation example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <Playground mdxType="Playground">
      <pre><code parentName="pre" {...{
          "className": "language-jsx"
        }}>{`<Accordion
  card
  language="en"
  heading="Customised accordion"
  headingLevel={2}
  style={{
    maxWidth: '360px'
  }}
  theme={{
    '--background-color': 'var(--color-bus)',
    '--header-focus-outline-color': 'var(--color-white)',
    '--button-size': '28px',
    '--content-font-color': 'var(--color-white)',
    '--content-font-size': 'var(--fontsize-body-m)',
    '--content-line-height': 'var(--lineheight-l)',
    '--header-font-color': 'var(--color-white)',
    '--header-font-size': 'var(--fontsize-heading-s)',
    '--header-line-height': 'var(--lineheight-s)',
    '--padding-horizontal': 'var(--spacing-m)',
    '--padding-vertical': '20px'
  }}
>
  In order to customise the accordion component, use the theme property.
</Accordion>
`}</code></pre>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      